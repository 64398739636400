import { GetAllInvestor, GetAllStartup } from './Repos';
import { ERROR_CODES } from '../../src/Constants'
import { setInvestorsArray, getInvestorsArray, setStartupArray } from "../GlobalVariables";

export const getInvestersListApiCall = () => {
    GetAllInvestor().then((res) => {
        if (res.data.success === ERROR_CODES.SUCCESS) {
            setInvestorsArray(res.data.result)
            // debugger;

        }
    }).catch((error) => {
        alert("Exception"+ error);
    });
}

export const getStartupListApiCall = () => {
    GetAllStartup().then((res) => {
        if (res.data.success === ERROR_CODES.SUCCESS) {
            setStartupArray(res.data.result)
        }
    }).catch((error) => {
        alert("Exception"+ error);
    })
}