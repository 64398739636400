import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import startup__details__img from "../Assets/startup__details__img.png";
import InputBox from "../Components/InputBox";
import MultiSelectInput from "../Components/MultiSelectInput";
import { addInvestor } from "../Apis/Repos";
import { ERROR_CODES, USER_KEYS } from "../Constants";
import { getInvestorsArray, setInvestorsArray } from "../GlobalVariables";
import { useLocation } from "react-router-dom";
import { isNullOrEmpty } from "../Utils/TextUtils";
import { isEmailInvalid } from "../Utils/Validations";
import Loader from "./Loader";

export default function AddEditInvestor({ isAdd }) {

  const location = useLocation();
  const navigate = useNavigate();

  const isEdit = () => {
    return location.state != null;
  }

  useEffect(()=>{
    const userId = localStorage.getItem("Admin");
    if(!userId){
      navigate("/");
    }
  },[])

  //#region options
  const investorTypeOption = [
    { value: "Investor Type1", label: "Venture Capitalist" },
    { value: "Investor Type2", label: "Angel Investor" },
    { value: "Investor Type3", label: "New Entrant" },
  ];
  const onChangeInvestorTypeDropDownValue = (event) => {
    setInvestorType(event);
  };
  const investorStartupStageOption = [
    { value: "Startup Stage interested in to invest1", label: "First Check" },
    { value: "Startup Stage interested in to invest2", label: "Pre-seed" },
    { value: "Startup Stage interested in to invest3", label: "Seed" },
    { value: "Startup Stage interested in to invest4", label: "Growth" },
  ];
  const onChangeInvestorStartupStageDropDownValue = (event) => {
    setInvestorStartupStage(event);
  };
  const investorTicketSizeOption = [
    { value: "Ticket Size to Invest1", label: "<$100,000" },
    { value: "Ticket Size to Invest2", label: "$100,000-$500,000" },
    { value: "Ticket Size to Invest3", label: "$500,000-$900,000" },
    { value: "Ticket Size to Invest4", label: "$1 mn - $2 mn" },
    { value: "Ticket Size to Invest5", label: ">$ 2 mn" },
  ];
  const onChangeInvestorTicketSizeDropDownValue = (event) => {
    setInvestorTicketSize(event);
  };
  const investorIndustryInterestedInOption = [
    { value: "Industry Interested In1", label: "Fintech" },
    { value: "Industry Interested In2", label: "Edtech" },
    { value: "Industry Interested In3", label: "Logistic" },
    { value: "Industry Interested In4", label: "E-commerce" },
    { value: "Industry Interested In5", label: "Agritech" },
    { value: "Industry Interested In6", label: "IT Solution" },
    { value: "Industry Interested In7", label: "Entertainment" },
  ];
  const onChangeInvestorIndustryInterestedInDropDownValue = (event) => {
    setInvestorIndustry(event);
  };
  const investorBusinessModelInterestedInOption = [
    { value: "Business Model Interest In1", label: "B2B" },
    { value: "Business Model Interest In2", label: "B2C" },
    { value: "Business Model Interest In3", label: "C2C" },
    { value: "Business Model Interest In4", label: "B2G" },
  ];
  const onChangeInvestorBusinessModelInterestedInDropDownValue = (event) => {
    setInvestorBusinessModel(event);
  };
  const investorExitPlanOption = [
    { value: "Exit Plan1", label: "< 5 years" },
    { value: "Exit Plan2", label: "5 - 10 years" },
    { value: "Exit Plan3", label: "> 10 years" },
  ];
  const onChangeInvestorExitPlanDropDownValue = (event) => {
    setInvestorPlan(event);
  };
  //#endregion

  //#region Variables

  const [investorId, setInvestorId] = useState(location.state ? location.state.investorDataToPass.Id : 0)
  const [investorUserId, setInvestorUserId] = useState(location.state && location.state.investorDataToPass.usermeta[0] ? location.state.investorDataToPass.usermeta[0].UserId : 0);

  var [investorName, setInvestorName] = useState(location.state && location.state.investorDataToPass.usermeta[0] ? location.state.investorDataToPass.usermeta[0].UserValue : "");
  const [investorNameErrorMessageVisibility, setInvestorNameErrorMessageVisibility] = useState(false);
  const [investorNameErrorMessage, setInvestorNameErrorMessage] = useState("");

  var [investorEmail, setInvestorEmail] = useState(location.state ? location.state.investorDataToPass.Email : "");
  const [investorEmailErrorMessageVisibility, setInvestorEmailErrorMessageVisibility] = useState(false);
  const [investorEmailErrorMessage, setInvestorEmailErrorMessage] = useState("");

  let investorTypeReceivedData = location.state && location.state.investorDataToPass.usermeta[1] ? location.state.investorDataToPass.usermeta[1].UserValue  : 0
 
 
  var [investorType, setInvestorType] = useState(location.state && location.state.investorDataToPass.usermeta[1] ? [{ label: location.state.investorDataToPass.usermeta[1].UserValue }] : 0);
  debugger
  const [investorTypeErrorMessageVisibility, setInvestorTypeErrorMessageVisibility] = useState(false);
  const [investorTypeErrorMessage, setInvestorTypeErrorMessage] = useState("");

  var [investorStartupStage, setInvestorStartupStage] = useState(location.state && location.state.investorDataToPass.usermeta[2] ? [{ label: location.state.investorDataToPass.usermeta[2].UserValue  }] : 0);
  debugger
  const [investorStartupStageErrorMessageVisibility, setInvestorStartupStageErrorMessageVisibility] = useState(false);
  const [investorStartupStageErrorMessage, setInvestorStartupStageErrorMessage] = useState("");

  var [investorTicketSize, setInvestorTicketSize] = useState(location.state && location.state.investorDataToPass.usermeta[3] ? { label: location.state.investorDataToPass.usermeta[3].UserValue } : 0);
  const [investorTicketSizeErrorMessageVisibility, setInvestorTicketSizeErrorMessageVisibility] = useState(false);
  const [investorTicketSizeErrorMessage, setInvestorTicketSizeErrorMessage] = useState("");

  var [investorIndustry, setInvestorIndustry] = useState(location.state && location.state.investorDataToPass.usermeta[4] ? [{ label: location.state.investorDataToPass.usermeta[4].UserValue }] : 0);
  const [investorIndustryErrorMessageVisibility, setInvestorIndustryErrorMessageVisibility] = useState(false);
  const [investorIndustryErrorMessage, setInvestorIndustryErrorMessage] = useState("");

  var [investorBusinessModel, setInvestorBusinessModel] = useState(location.state && location.state.investorDataToPass.usermeta[5] ? [{ label: location.state.investorDataToPass.usermeta[5].UserValue }] : 0);
  const [investorBusinessModelErrorMessageVisibility, setInvestorBusinessModelErrorMessageVisibility] = useState(false);
  const [investorBusinessModelErrorMessage, setInvestorBusinessModelErrorMessage] = useState("");

  var [investorPlan, setInvestorPlan] = useState(location.state && location.state.investorDataToPass.usermeta[6] ? [{ label: location.state.investorDataToPass.usermeta[6].UserValue }] : 0);
  const [investorPlanErrorMessageVisibility, setInvestorPlanErrorMessageVisibility] = useState(false);
  const [investorPlanErrorMessage, setInvestorPlanErrorMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Api Call

  const investorUsermetaInfo = (Id, userId, userKey, userValue) => {
    return {
      "Id": Id,
      "UserId": userId,
      "UserKey": userKey,
      "UserValue": userValue,
    }
  }

  const addEditInvestorApiCall = () => {
    debugger;
    var investorTypedata = "";
    if (investorType) {
      investorType.forEach(element => {
        investorTypedata = investorTypedata + element.label + ", ";
        debugger
      });
    }
    // else { debugger; return investorTypedata }
    var investorStartupStagedata = "";
    if (investorStartupStage) {
      investorStartupStage.forEach(element => {
        investorStartupStagedata = investorStartupStagedata + element.label + ", ";
      });
    }
    var investorIndustryData = "";
    if (investorIndustry) {
      investorIndustry.forEach(element => {
        investorIndustryData = investorIndustryData + element.label + ", ";
      });
    }
    var investorBusinessModelData = "";
    if (investorBusinessModel) {
      investorBusinessModel.forEach(element => {
        investorBusinessModelData = investorBusinessModelData + element.label + ", ";
      });
    }
    var investorPlanData = "";
    if (investorPlan) {
      investorPlan.forEach(element => {
        investorPlanData = investorPlanData + element.label + ", ";
      });
    }

    investorTypedata = investorTypedata.slice(0, investorTypedata.length - 2);
    investorStartupStagedata = investorStartupStagedata.slice(0, investorStartupStagedata.length - 2);
    investorIndustryData = investorIndustryData.slice(0, investorIndustryData.length - 2);
    investorBusinessModelData = investorBusinessModelData.slice(0, investorBusinessModelData.length - 2);
    investorPlanData = investorPlanData.slice(0, investorPlanData.length - 2);

    let startupInfo = {
      "Id": investorId ? investorId : 0,
      "UserType": USER_KEYS.INVESTOR_USER_TYPE,
      "Email": investorEmail,
      "attachmnet_base64": null,
      "attachment_path": null,
      "usermeta": [
        investorUsermetaInfo(0, investorUserId, USER_KEYS.INVESTOR_NAME, investorName),
        investorUsermetaInfo(0, investorUserId, USER_KEYS.INVESTOR_TYPE, investorTypedata),
        investorUsermetaInfo(0, investorUserId, USER_KEYS.INVESTOR_STARTUP_STAGE, investorStartupStagedata),
        investorUsermetaInfo(0, investorUserId, USER_KEYS.INVESTOR_TICKET_SIZE, investorTicketSize.label),
        investorUsermetaInfo(0, investorUserId, USER_KEYS.INVESTOR_INDUSTRY, investorIndustryData),
        investorUsermetaInfo(0, investorUserId, USER_KEYS.INVESTOR_BUSINESS_MODEL, investorBusinessModelData),
        investorUsermetaInfo(0, investorUserId, USER_KEYS.INVESTOR_EXIT_PLAN, investorPlanData)
      ]

    }
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addInvestor(startupInfo).then((res) => {
        setIsLoading(false);
        debugger
        if (res.data.success === ERROR_CODES.SUCCESS) {
          if (isEdit()) {
            setInvestorsArray(location.state.investorDataToPass, res.data.result)
            navigate("/Dashboard/investor");
          }
          else {
            setInvestorsArray(res.data.result)
            navigate("/Dashboard/investor");
            debugger
          }
        }
        else {
          alert("can't save data, please try later");
        }
      }).catch((error) => {
        setIsLoading(false);
        alert("Exception"+ error);
      });
    }
  }

  //#endregion

  //#region Investor Name Validation
  const onInvestorNameTextChangeListener = (event) => {
    setInvestorName(investorName = event.currentTarget.value);
    investorNameErrorMessageAndVisibilityAndIsValid(investorName);
  }

  const investorNameErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      investorNameErrorMessageAndVisibility(true, "Name must not be empty");
    }
    else {
      investorNameErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;

  }

  const investorNameErrorMessageAndVisibility = (visibility, text) => {
    setInvestorNameErrorMessageVisibility(visibility);
    setInvestorNameErrorMessage(text);
  }

  //#endregion

  //#region Investor Email Validation
  const onInvestorEmailTextChangeListener = (event) => {
    setInvestorEmail(investorEmail = event.currentTarget.value);
    investorEmailErrorMessageAndVisibilityAndIsValid(investorEmail);
  }

  const investorEmailErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredTexet)) {
      investorEmailErrorMessageAndVisibility(true, "Email must not be empty");
    }
    else if (isEmailInvalid(enteredTexet)) {
      investorEmailErrorMessageAndVisibility(true, "Enter valid email");
    }
    else {
      investorEmailErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;

  }

  const investorEmailErrorMessageAndVisibility = (visibility, text) => {
    setInvestorEmailErrorMessageVisibility(visibility);
    setInvestorEmailErrorMessage(text);
  }
  //#endregion

  //#region Investor Type Validation
  const onInvestorTypeTextChangeListener = (event) => {
    debugger;
    setInvestorType(investorType = event);
    investorTypeErrorMessageAndVisibilityAndIsValid(investorType);
  }

  const investorTypeErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (enteredTexet === 0 || enteredTexet.length === 0) {
      investorTypeErrorMessageAndVisibility(true, "Investor Type must not be empty");
      debugger
    }
    else {
      investorTypeErrorMessageAndVisibility(false, "");
      isViewValid = true;
      debugger
    }
    return isViewValid;

  }

  const investorTypeErrorMessageAndVisibility = (visibility, text) => {
    setInvestorTypeErrorMessageVisibility(visibility);
    setInvestorTypeErrorMessage(text);
  }
  //#endregion

  //#region Investor Startup Stage Validation
  const onInvestorStartupStageTextChangeListener = (event) => {
    setInvestorStartupStage(investorStartupStage = event);
    investorStartupStageErrorMessageAndVisibilityAndIsValid(investorStartupStage);
  }

  const investorStartupStageErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (enteredTexet === 0 || enteredTexet.length === 0) {
      investorStartupStageErrorMessageAndVisibility(true, "Startup Stage must not be empty");
    }
    else {
      investorStartupStageErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;

  }

  const investorStartupStageErrorMessageAndVisibility = (visibility, text) => {
    setInvestorStartupStageErrorMessageVisibility(visibility);
    setInvestorStartupStageErrorMessage(text);
  }
  //#endregion

  //#region Investor Ticket Size Validation
  const onInvestorTicketSizeTextChangeListener = (event) => {
    debugger;
    setInvestorTicketSize(investorTicketSize = event);
    investorTicketSizeErrorMessageAndVisibilityAndIsValid(investorTicketSize);
  }

  const investorTicketSizeErrorMessageAndVisibilityAndIsValid = (enteredTexet) => {
    var isViewValid = false;
    if (enteredTexet === 0) {
      investorTicketSizeErrorMessageAndVisibility(true, "Ticket Size must not be empty");
    }
    else {
      investorTicketSizeErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;

  }

  const investorTicketSizeErrorMessageAndVisibility = (visibility, text) => {
    setInvestorTicketSizeErrorMessageVisibility(visibility);
    setInvestorTicketSizeErrorMessage(text);
  }
  //#endregion

  //#region Investor Industry Validation
  const onInvestorIndustryTextChangeListener = (event) => {
    setInvestorIndustry(investorIndustry = event);
    investorIndustryErrorMessageAndVisibilityAndIsValid(investorIndustry);
  }

  const investorIndustryErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (enteredText === 0 || enteredText.length === 0) {
      investorIndustryErrorMessageAndVisibility(true, "Industry must not be empty");
    }
    else {
      investorIndustryErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;

  }

  const investorIndustryErrorMessageAndVisibility = (visibility, text) => {
    setInvestorIndustryErrorMessageVisibility(visibility);
    setInvestorIndustryErrorMessage(text);
  }
  //#endregion

  //#region Investor Business Model Validation
  const onInvestorBusinessModelTextChangeListener = (event) => {
    setInvestorBusinessModel(investorBusinessModel = event);
    investorBusinessModelErrorMessageAndVisibilityAndIsValid(investorBusinessModel);
  }

  const investorBusinessModelErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (enteredText === 0 || enteredText.length === 0) {
      investorBusinessModelErrorMessageAndVisibility(true, "Business Model must not be empty");
    }
    else {
      investorBusinessModelErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;

  }

  const investorBusinessModelErrorMessageAndVisibility = (visibility, text) => {
    setInvestorBusinessModelErrorMessageVisibility(visibility);
    setInvestorBusinessModelErrorMessage(text);
  }
  //#endregion

  //#region Investor Plan Validation
  const onInvestorPlanTextChangeListener = (event) => {
    setInvestorPlan(investorPlan = event);
    investorPlanErrorMessageAndVisibilityAndIsValid(investorPlan);
  }

  const investorPlanErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (enteredText === 0 || enteredText.length === 0) {
      investorPlanErrorMessageAndVisibility(true, "Plan must not be empty");
    }
    else {
      investorPlanErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;

  }

  const investorPlanErrorMessageAndVisibility = (visibility, text) => {
    setInvestorPlanErrorMessageVisibility(visibility);
    setInvestorPlanErrorMessage(text);
  }
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = investorNameErrorMessageAndVisibilityAndIsValid(investorName)
    debugger
    if (viewValid)
      viewValid = investorEmailErrorMessageAndVisibilityAndIsValid(investorEmail)
    debugger
    if (viewValid)
      viewValid = investorTypeErrorMessageAndVisibilityAndIsValid(investorType)
    debugger
    if (viewValid)
      viewValid = investorStartupStageErrorMessageAndVisibilityAndIsValid(investorStartupStage)
    debugger
    if (viewValid)
      viewValid = investorTicketSizeErrorMessageAndVisibilityAndIsValid(investorTicketSize)
    debugger
    if (viewValid)
      viewValid = investorIndustryErrorMessageAndVisibilityAndIsValid(investorIndustry)
    if (viewValid)
      viewValid = investorBusinessModelErrorMessageAndVisibilityAndIsValid(investorBusinessModel)
    if (viewValid)
      viewValid = investorPlanErrorMessageAndVisibilityAndIsValid(investorPlan)

    return viewValid
  }
  //#endregion


  return (
    <div className="main__container">
      {
        isLoading ? (
          <Loader/>
        ) : (
          <>
          
      <div className="main__container__header">
        <div className="main__container__header__heading">Investors</div>
        <div className="main__container__header__buttons"></div>
      </div>
      <div className="main__container__sub__heading">
        <button
          onClick={() => {
            navigate("/Dashboard/investor");
          }}
          className="main__container__sub__heading__btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.556"
            height="17.629"
            viewBox="0 0 20.556 17.629"
          >
            <g id="_507257" data-name="507257" transform="translate(0 -31.842)">
              <g
                id="Group_506"
                data-name="Group 506"
                transform="translate(0 31.842)"
              >
                <path
                  id="Path_753"
                  data-name="Path 753"
                  d="M19.32,39.213a1.469,1.469,0,0,0-.254-.019H4.564l.316-.147a2.941,2.941,0,0,0,.831-.588l4.067-4.067a1.522,1.522,0,0,0,.213-1.949,1.471,1.471,0,0,0-2.206-.191L.431,39.606a1.471,1.471,0,0,0,0,2.08h0l7.354,7.354a1.471,1.471,0,0,0,2.206-.147,1.522,1.522,0,0,0-.213-1.949L5.718,42.871a2.942,2.942,0,0,0-.735-.537l-.441-.2H18.984A1.522,1.522,0,0,0,20.536,40.9,1.471,1.471,0,0,0,19.32,39.213Z"
                  transform="translate(0 -31.842)"
                  fill="#d39844"
                />
              </g>
            </g>
          </svg>
        </button>
        {isEdit() ? "Edit Investor" : "Add Investor"}
      </div>
      <div className="main__container__content">
        <div className="add__startup__main__container__content" action="">
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Investor Name
              </div>
              <InputBox
                placeholder="Name"
                type="text"
                value={investorName}
                error={investorNameErrorMessageVisibility}
                errorMessage={investorNameErrorMessage}
                onChange={(event) => onInvestorNameTextChangeListener(event)}
              />
            </div>
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Email Address
              </div>
              <InputBox
                placeholder="Email"
                type="email"
                value={investorEmail}
                error={investorEmailErrorMessageVisibility}
                errorMessage={investorEmailErrorMessage}
                onChange={(event) => onInvestorEmailTextChangeListener(event)}
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Investor Type
              </div>
              <MultiSelectInput
                placeholder="Investor Type"
                isMulti={true}
                options={investorTypeOption}
                value={investorType}
                error={investorTypeErrorMessageVisibility}
                errorMessage={investorTypeErrorMessage}
                onChange={(event) => onInvestorTypeTextChangeListener(event)}
              />
            </div>
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Startup Stage Interested In To Invest
              </div>
              <MultiSelectInput
                placeholder="Interested In To Invest"
                isMulti={true}
                options={investorStartupStageOption}
                value={investorStartupStage}
                error={investorStartupStageErrorMessageVisibility}
                errorMessage={investorStartupStageErrorMessage}
                onChange={(event) => onInvestorStartupStageTextChangeListener(event)}
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Ticket Size To Invest
              </div>
              <InputBox
                placeholder="Ticket Size"
                type="text"
                variant="select"
                options={investorTicketSizeOption}
                value={investorTicketSize}
                error={investorTicketSizeErrorMessageVisibility}
                errorMessage={investorTicketSizeErrorMessage}
                onChange={(event) => onInvestorTicketSizeTextChangeListener(event)}
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Industry Interested In
              </div>
              <MultiSelectInput
                placeholder="Industry Interested In"
                isMulti={true}
                options={investorIndustryInterestedInOption}
                value={investorIndustry}
                error={investorIndustryErrorMessageVisibility}
                errorMessage={investorIndustryErrorMessage}
                onChange={(event) => onInvestorIndustryTextChangeListener(event)}
              />
            </div>
          </div>
          <div className="add__startup__main__container__content__entry">
            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Business Model Interest In
              </div>
              <MultiSelectInput
                placeholder="Business Interested In"
                isMulti={true}
                options={investorBusinessModelInterestedInOption}
                value={investorBusinessModel}
                error={investorBusinessModelErrorMessageVisibility}
                errorMessage={investorBusinessModelErrorMessage}
                onChange={(event) => onInvestorBusinessModelTextChangeListener(event)}
              />
            </div>

            <div className="add__startup__main__container__content__entry__content">
              <div className="add__startup__main__container__content__entry__content__heading">
                Exit Plan
              </div>
              <MultiSelectInput
                placeholder="Exit Plan"
                isMulti={true}
                options={investorExitPlanOption}
                value={investorPlan}
                error={investorPlanErrorMessageVisibility}
                errorMessage={investorPlanErrorMessage}
                onChange={(event) => onInvestorPlanTextChangeListener(event)}
              />
            </div>
          </div>

          {/* <div className="add__startup__main__container__content__entry__content__heading">
            Upload Image
          </div>
          <InputBox variant="upload" /> */}
          {/* <div className="add__startup__main__container__content__entry__content__heading">Answer</div>
        <textarea
          cols="30"
          rows="6"
          placeholder="Branch"
          className="table__details__container__text__box__input"
        /> */}
          <div className="popup__cta__button">
            <button
              onClick={() => addEditInvestorApiCall()}
              style={{
                marginTop: "2em",
                marginBottom: "1em",
              }}
              className="primary__button"
            >
              {"Save"}
            </button>
          </div>
        </div>
      </div>
          </>

        )
      }
    </div>
  );
}
