import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import TableEntryHeadings from "../Components/TableEntryHeadings";
import TableEntryRows from "../Components/TableEntryRows";
import Loader from "./Loader";
import tableEntryPic from "../Assets/tableEntryPic.png";
import { getAllContacts } from "../Apis/Repos";
import { ERROR_CODES } from "../Constants";
import { useNavigate, useLocation } from "react-router-dom";

export default function Contact() {
  const navigate = useNavigate();
  const tableHeadingRow = [
    { heading: "Name" },
    { heading: "Last Name" },
    { heading: "Email" },
    { heading: "Date" },
    { heading: "Subject" },
    { heading: "Phone" },
    { heading: "Message" },
  ];

  useEffect(() => {
    getAllContactsApiCall();
    const userId = localStorage.getItem("Admin");
    if(!userId){
      navigate("/");
    }
  }, []);

  //#region Variables
  const [contactsData, setContactsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Api Calls
  const getAllContactsApiCall = () => {
    setIsLoading(true);
    getAllContacts()
      .then((res) => {
        setIsLoading(false);
        if (res.data.success === ERROR_CODES.SUCCESS) {
          setContactsData(res.data.result);
        } else {
          alert("");
        }
        console.log("getAllContacts Response", res.data.result);
      })
      .catch((error) => {
        setIsLoading(false);
        alert("Exception", error);
      });
  };
  //#endregion

  return (
    <div className="main__container">
      <div className="main__container__header">
        <div className="main__container__header__heading">Contact</div>
        <div className="main__container__header__buttons"></div>
      </div>
      <div className="main__container__content">
        <div className="main__container__content__table">
          <div className="table__container__header">
            <div
              className="table__container__header__entry"
              style={{
                minWidth: 200,
                paddingLeft: "2em",
              }}
            >
              Name
            </div>
            {/* <div
              className="table__container__header__entry"
              style={{ minWidth: 200 }}
            >
              Last Name
            </div> */}
            <div
              className="table__container__header__entry"
              style={{ minWidth: 250 }}
            >
              Email
            </div>
            
            <div
              className="table__container__header__entry"
              style={{ minWidth: 180 }}
            >
              Subject
            </div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 180 }}
            >
              Phone
            </div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 200 }}
            >
              Message
            </div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 180 }}
            >
              Date
            </div>
          </div>
          {
            isLoading ? (
              <Loader/>
            ) : (
          contactsData.length > 0 && contactsData !== null ? (
            contactsData.map((data) => (
              <div class="entry__info__row">
                {/* <div style={{ marginRight: 10 }}>
                  <Button
                    variant="rounded"
                    svg={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-trash"
                      >
                        <polyline points="3 6 5 6 21 6"></polyline>
                        <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      </svg>
                    }
                    type="button"
                    className="rounded__btn__primary"
                    onClick={() => { }}
                  />
                </div> */}
                <div
                  class="entry__info__row__text"
                  style={{
                    minWidth: 200,
                    paddingLeft: "2em",
                  }}
                >
                  {data.ContactPerson}
                </div>
                {/* <div class="entry__info__row__text" style={{ minWidth: 200 }}>
                  {data.ContactPerson}
                </div> */}
                <div class="entry__info__row__text" style={{ minWidth: 250 }}>
                  {data.Email}
                </div>
                
                <div class="entry__info__row__text" style={{ minWidth: 180 }}>
                  {data.Subject}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 180 }}>
                  {data.Phoneno}
                </div>
                <div
                  className="entry__info__row__text"
                  style={{ minWidth: 200 }}
                >
                  <a>
                    View Message
                    <div className="entry__info__row__text__message">
                      {data.Message}
                    </div>
                  </a>
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 180 }}>
                  {data.Date}
                </div>
                {/* <div style={{ marginRight: 10 }}>
                  <Button
                    placeholder="Delete"
                    type="button"
                    className="primary__button"
                    onClick={() => { }}
                  />
                </div> */}
              </div>
            )
            )
            ) :<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>
          ) }
        </div>
      </div>
    </div>
  );
}
