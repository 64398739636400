import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import TableEntryHeadings from "../Components/TableEntryHeadings";
import TableEntryRows from "../Components/TableEntryRows";
import { GetAllMilestone, deleteMilestone } from "../Apis/Repos";
import Loader from "./Loader";
import { ERROR_CODES } from "../Constants";
import AddMilestone from "./AddMilestone";
import { useNavigate, useLocation } from "react-router-dom";

export default function Milestones({ setIsAddMilestone, setIsEditMilestone }) {
  const navigate = useNavigate();
  const tableHeadingRow = [
    { heading: "" },
    { heading: "Title" },
    { heading: "Value" },
    { heading: "Description" },
    { heading: "" },
  ];

  useEffect(() => {
    milestoneList();
    const userId = localStorage.getItem("Admin");
    if(!userId){
      navigate("/");
    }
  }, []);

  //#region Variables
  const [milestoneData, setMilestoneData] = useState([]);
  const [milestoneAddEditDialogVisibility, setMilestoneAddEditVisibility] =
    useState(false);
  const [milestoneSlectedToEdit, setMilestoneSelectedToEdit] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Api Calls
  const milestoneList = () => {
    debugger;
    setIsLoading(true);
    GetAllMilestone()
      .then((res) => {
        setIsLoading(false);
        if (res.data.success === ERROR_CODES.SUCCESS) {
          debugger;
          setMilestoneData(res.data.result);
        } else {
          alert("Can't load data, please try later");
        }
        console.log("GetAllMilestone Response", res.data.result);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const deleteMilestoneApiCall = (milestoneId) => {
    deleteMilestone(milestoneId.Id)
      .then((res) => {
        if (res.data.success === ERROR_CODES.SUCCESS) {
          var afterRemove = milestoneData.filter((x) => x.Id !== milestoneId.Id);
          setMilestoneData(afterRemove);
        } else {
          alert("Can't delete data, please try later");
        }
      })
      .catch((error) => {
        alert("Exception", error);
      });
  };

  //#endregion

  //#region other functions

  const onMilestoneEditClickListener = (milestoneSelectedData) => {
    setMilestoneSelectedToEdit(milestoneSelectedData);
    debugger;
    setMilestoneAddEditVisibility(true);
  };

  //#endregion

  return (
    <div className="main__container">
      <div className="main__container__header">
        <div className="main__container__header__heading">Milestones</div>
        <div className="main__container__header__buttons">
          {/* <Button
            placeholder="Add Milestone"
            className="primary__button"
            onClick={() => {
              setMilestoneAddEditVisibility(true);
            }}
          /> */}
        </div>
      </div>
      <div className="main__container__content">
        <div className="main__container__content__table">
          <div className="table__container__header">
            <div
              className="table__container__header__entry"
              style={{ minWidth: 150 }}
            ></div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 250 }}
            >
              Title
            </div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 150 }}
            >
              Value
            </div>
            <div
              className="table__container__header__entry"
              style={{ minWidth: 180 }}
            >
              Discription
            </div>
          </div>
          {
          isLoading ? (
            <Loader/>
          ) : (

          milestoneData.length > 0 ? (
            milestoneData.map((data, index) => (
              <div style={{ width: 160 }} class="entry__info__row">
                <div
                  className="entry__info__row__text"
                  style={{ minWidth: 150 }}
                >
                  <div style={{ marginRight: 6 }}>
                    <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-2"
                        >
                          <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                        </svg>
                      }
                      className="rounded__btn__secondary"
                      onClick={() => onMilestoneEditClickListener(data)}
                    />
                  </div>
                  <div>
                    {/* <Button
                      variant="rounded"
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-trash"
                        >
                          <polyline points="3 6 5 6 21 6"></polyline>
                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                        </svg>
                      }
                      type="button"
                      className="rounded__btn__primary"
                      onClick={() => deleteMilestoneApiCall(data)}
                    /> */}
                  </div>
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 250 }}>
                  {data.Title}
                </div>
                <div class="entry__info__row__text" style={{ minWidth: 150 }}>
                  {data.Value}
                </div>
                <div
                  className="entry__info__row__text"
                  style={{ minWidth: 180 }}
                >
                  <a>
                    View Description
                    <div className="entry__info__row__text__message">
                      {data.Description}
                    </div>
                  </a>
                </div>
                {/* <div style={{ marginRight: 10 }}>
                  <Button
                    placeholder="Edit"
                    type="button"
                    className="secondary__button"
                    onClick={() => onMilestoneEditClickListener(data)}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <Button
                    placeholder="Delete"
                    type="button"
                    className="primary__button"
                    onClick={() => deleteMilestoneApiCall(data)}
                  />
                </div> */}
              </div>
          )
            )) :<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>
          ) }
        </div>
      </div>
      {milestoneAddEditDialogVisibility ? (
        <AddMilestone
          milestoneSlectedToEdit={milestoneSlectedToEdit}
          onDialogCrossClicked={() => {
            setMilestoneAddEditVisibility(false);
            setMilestoneSelectedToEdit(null);
          }}
          onMilestoneAddedSuccessfully={(addedMilestoneData) => {
            milestoneData.push(addedMilestoneData);
            // milestoneList();
            setMilestoneAddEditVisibility(false);
          }}
          onMilestoneEditedSuccessfully={(
            oldMilestoneData,
            updatedMilestoneData
          ) => {
            var updatedMilestoneIndex = milestoneData.findIndex(
              (element) => element === oldMilestoneData
            );
            milestoneData[updatedMilestoneIndex] = updatedMilestoneData;
            debugger;
            setMilestoneAddEditVisibility(false);
          }}
        />
      ) : null}
    </div>
  );
}
