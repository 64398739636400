import Axios from "axios";

//#region Login
export const RegisterAdmin = (data) => {
    return Axios.post(process.env.REACT_APP_API_URL + "/api/systemuser/UserLogin", data);
};
//#endregion

//#region Investors
export const GetAllInvestor = () => {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/User/GetAllInvestor");
};


export const addInvestor = (investorInfo) => {
    const URL = process.env.REACT_APP_API_URL + "/api/User/SaveUserInfo";
    console.log('Add/Edit Investor ' + URL + ' Request', investorInfo)
    return Axios.post(URL, investorInfo,
        {
            ["axios-retry"]: {
                retries: 5,
            },
        }
    )
}
//#endregion

//#region Startup
export const GetAllStartup = () => {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/User/GetAllStartup");
};

export const addStartUp = (startupInfo) => {
    const URL = process.env.REACT_APP_API_URL + "/api/User/SaveUserInfo";
    console.log('Add/Edit Start-Up ' + URL + ' Request', startupInfo)
    return Axios.post(URL, startupInfo,
        {
            ["axios-retry"]: {
                retries: 5,
            },
        }
    )
}

export const deleteInvestorOrStartUp = (id) => {
    const URL = process.env.REACT_APP_API_URL + "/api/User/DeleteById"
    var requestObject = {
        Id: id,
    };
    console.log("Delete Investor/Start-Up Request " + URL + " Data", requestObject)
    return Axios.post(URL, requestObject);
}
//#endregion

//#region Client
export const GetAllClients = () => {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/Client/GetAllClient");
};

export const addClient = (clientInfo) => {
    const URL = process.env.REACT_APP_API_URL + "/api/Client/Post";
    console.log("Add/Edit Client Request " + URL + " Data", clientInfo)
    return Axios.post(URL, clientInfo,
        {
            ["axios-retry"]: {
                retries: 5,
            },
        }
    )
}

export const deleteClient = (id) => {
    const URL = process.env.REACT_APP_API_URL + "/api/Client/Delete"
    var requestObject = {
        Id: id,
    };
    console.log("Delete Client Request " + URL + " Data", requestObject)
    return Axios.post(URL, requestObject);
}
//#endregion

//#region MileStone
export const GetAllMilestone = () => {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/Milestone/GetAllMileStone");
};

export const addMilestone = (milestoneData) => {
    const URL = process.env.REACT_APP_API_URL + "/api/Milestone/Post";
    console.log("Add/Edit Milestone Request " + URL + " Data", milestoneData)
    return Axios.post(URL, milestoneData);
}

export const deleteMilestone = (id) => {
    const URL = process.env.REACT_APP_API_URL + "/api/Milestone/Delete"
    var requestObject = {
        Id: id,
    };
    console.log("Delete Milestone Request " + URL + " Data", requestObject)
    return Axios.post(URL, requestObject);
}
//#endregion

//#region FAQs
export const getAllFAQs = () => {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/Faq/GetAllFAQ");
}

export const addFAQ = (faqData) => {
    const URL = process.env.REACT_APP_API_URL + "/api/Faq/Post"
    console.log("Add/Edit FAQ Request " + URL + " Data", faqData)
    return Axios.post(URL, faqData);
}

export const deleteFAQ = (id) => {
    const URL = process.env.REACT_APP_API_URL + "/api/Faq/Delete"
    var requestObject = {
        Id: id,
    };
    console.log("Delete FAQ Request " + URL + " Data", requestObject)
    return Axios.post(URL, requestObject);
}
//#endregion

//#region Contacts
export const getAllContacts = () => {
    return Axios.get(process.env.REACT_APP_API_URL + "/api/Contactus/GetAll")
}
//#endregion
