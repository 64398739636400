import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import InputBox from "../Components/InputBox";
import { addFAQ } from "../Apis/Repos";
import { ERROR_CODES } from "../Constants";
import { isNullOrEmpty } from "../Utils/TextUtils";
import MultiSelectInput from "../Components/MultiSelectInput";
import Loader from "./Loader";

export default function AddQuestion(props) {
  useEffect(() => {
    onUserTypeEditClickListener();
  }, []);

  let options = [
    { value: 1, label: "investor" },
    { value: 2, label: "startup" },
  ];

  //#region otherfunctions
  const isEdit = () => {
    return props.faqSelectedToEdit !== null;
  };

  const onFAQAddClickListener = () => {
    if (isEdit()) {
      editFAQApiCall();
    } else {
      addFAQApiCall();
    }
  };

  const onChangeUserTypehDropDownValue = (event) => {
    console.log("value", event.value);
    setUserType(event);
  };

  const onUserTypeEditClickListener = () => {
    if (userTypeToEdit !== null && userTypeToEdit.UserType === 1) {
      setUserType((userType = { label: "Investor",value:1 }));
      debugger;
    } else if (userTypeToEdit !== null && userTypeToEdit.UserType === 2) {
      setUserType((userType = { label: "startup", value:2 }));
      debugger;
    }
  };

  //#endregion

  //#region Variable

  const [faqId, setFaqId] = useState(
    props.faqSelectedToEdit ? props.faqSelectedToEdit.Id : null
  );

  var userTypeToEdit =
    isEdit() && props.faqSelectedToEdit != null
      ? props.faqSelectedToEdit
      : null;
  debugger;
  var [userType, setUserType] = useState(
    props.faqSelectedToEdit ? props.faqSelectedToEdit.UserType : 0
  );
  const [userTypeErrorMessage, setUserTypeErrorMessage] = useState("");
  const [userTypeErrorMessageVisibility, setUserTypeErrorMessageVisibility] =
    useState(false);

  var [question, setQuestion] = useState(
    props.faqSelectedToEdit ? props.faqSelectedToEdit.Question : ""
  );
  const [questionErrorMessage, setQuestionErrorMessage] = useState("");
  const [questionErrorMessageVisibility, setQuestionErrorMessageVisibility] =
    useState(false);

  var [answer, setAnswer] = useState(
    props.faqSelectedToEdit ? props.faqSelectedToEdit.Answer : ""
  );
  const [answerErrorMessage, setAnswerErrorMessage] = useState("");
  const [answerErrorMessageVisibility, setAnswerErrorMessageVisibility] =
    useState(false);

    const [isLoading, setIsLoading] = useState(false);

  //#endregion

  //#region Api Call

  const addFAQApiCall = () => {
    let faqData = {
      Question: question,
      Answer: answer,
      UserType: userType.value,
      IsActive: true,
    };
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addFAQ(faqData)
        .then((res) => {
          setIsLoading(false);
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            props.onFAQAddedSuccessfully(res.data.result);
          } else {
            alert("Can't add data, please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception"+ error);
        });
    }
  };

  const editFAQApiCall = () => {
    let faqData = {
      Id: faqId,
      Question: question,
      Answer: answer,
      UserType: userType.value,
      IsActive: true,
    };
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addFAQ(faqData)
        .then((res) => {
          setIsLoading(false);
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            props.onFAQEditedSuccessfully(
              props.faqSelectedToEdit,
              res.data.result
            );
          } else {
            alert("Can't edit data, please try later");
          }
        })
        .catch((error) => {
          setIsLoading(false)
          alert("Exception"+ error);
        });
    }
  };
  //#endregion

  //#region Question Validation
  const onQuestionTextChangeListener = (event) => {
    setQuestion((question = event.currentTarget.value));
    setQuestionErrorMessageAndVisibilityAndIsValid(question);
  };
  const setQuestionErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setQuestionErrorMessageAndVisibility(true, "Question must not be empty");
    } else {
      setQuestionErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setQuestionErrorMessageAndVisibility = (visibility, text) => {
    setQuestionErrorMessageVisibility(visibility);
    setQuestionErrorMessage(text);
  };
  //#endregion

  //#region Answer Validation
  const onAnswerTextChangeListener = (event) => {
    setAnswer((answer = event.currentTarget.value));
    setAnswerErrorMessageAndVisibilityAndIsValid(answer);
  };
  const setAnswerErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setAnswerErrorMessageAndVisibility(true, "Answer must not be empty");
    } else {
      setAnswerErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setAnswerErrorMessageAndVisibility = (visibility, text) => {
    setAnswerErrorMessageVisibility(visibility);
    setAnswerErrorMessage(text);
  };
  //#endregion

  //#region user type validation
  const onUserTypeTextChangeListener = (event) => {
    setUserType((userType = event));
    setUserTypeErrorMessageAndVisibilityAndIsValid(userType);
  };
  const setUserTypeErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (enteredText === 0) {
      setUserTypeErrorMessageAndVisibility(true, "user type must be selected");
    } else {
      setUserTypeErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setUserTypeErrorMessageAndVisibility = (visibility, text) => {
    setUserTypeErrorMessageVisibility(visibility);
    setUserTypeErrorMessage(text);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setUserTypeErrorMessageAndVisibilityAndIsValid(userType);
    if (viewValid)
      viewValid = setQuestionErrorMessageAndVisibilityAndIsValid(question);
    if (viewValid)
      viewValid = setAnswerErrorMessageAndVisibilityAndIsValid(answer);

    return viewValid;
  };
  //#endregion

  return (
    <div className="popup__container">
      {isLoading ?
      (
        <Loader/>
      ) : (

      <div action="" className="popup__container__form">
        <div className="popup__container__form__header">
          <div>{isEdit() ? "Edit Question" : "Add Question"}</div>
          <button
            onClick={() => {
              props.onDialogCrossClicked(false);
            }}
            className="popup__container__form__close__btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.829"
              height="14.829"
              viewBox="0 0 14.829 14.829"
            >
              <g id="x" transform="translate(-4.586 -4.586)">
                <line
                  id="Line_20"
                  data-name="Line 20"
                  x1="12"
                  y2="12"
                  transform="translate(6 6)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_21"
                  data-name="Line 21"
                  x2="12"
                  y2="12"
                  transform="translate(6 6)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </button>
        </div>
        <div className="popup__container__form__heading">User Type</div>

        <InputBox
          placeholder="User Type"
          variant="select"
          // isMulti={false}
          options={options}
          value={userType}
          error={userTypeErrorMessageVisibility}
          errorMessage={userTypeErrorMessage}
          onChange={(e) => onUserTypeTextChangeListener(e)}
        />
        <div
          className="popup__container__form__heading"
          style={{ marginTop: "1em" }}
        >
          Question
        </div>
        <InputBox
          placeholder="Question"
          type="text"
          value={question}
          error={questionErrorMessageVisibility}
          errorMessage={questionErrorMessage}
          onChange={(event) => onQuestionTextChangeListener(event)}
        />
        <div className="popup__container__form__heading">Answer</div>
        <InputBox
          placeholder="Answer"
          type="text"
          variant="textbox"
          value={answer}
          error={answerErrorMessageVisibility}
          errorMessage={answerErrorMessage}
          onChange={(event) => onAnswerTextChangeListener(event)}
        />
        <button
          onClick={() => {
            onFAQAddClickListener();
          }}
          style={{ marginTop: "1.5em", marginBottom: "1em" }}
          className="primary__button"
        >
          Save
        </button>
      </div>
      )
      }
    </div>
  );
}
