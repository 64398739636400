import React, { useState, useEffect } from "react";
import Button from "../Components/Button";
import InputBox from "../Components/InputBox";
import { useNavigate } from "react-router-dom";
import { RegisterAdmin } from "../Apis/Repos";
import { isNullOrEmpty } from "../Utils/TextUtils";
import { ERROR_CODES, VALIDATION_REGEX } from "../Constants";
import { isEmailInvalid } from "../Utils/Validations";

export default function Login() {
  const navigate = useNavigate();

  //#region Variables
  var [email, setEmail] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailErrorMessageVisibility, setEmailErrorMessageVisibility] =
    useState(false);

  var [password, setPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [passwordErrorMessageVisibility, setPasswordErrorMessageVisibility] =
    useState(false);

  const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
  //#endregion

  //#region Email Validation
  const onEmailTextChangeListener = (event) => {
    setEmail((email = event.currentTarget.value));
    setEmailErrorMessageAndVisibilityAndIsValid(email);
  };
  const setEmailErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      debugger;
      setEmailErrorMessageAndVisibility(true, "Email must not be empty");
      return isViewValid;
    } else if (isEmailInvalid(enteredText)) {
      debugger;
      setEmailErrorMessageAndVisibility(true, "Enter valid email");
      return isViewValid;
    } else {
      setEmailErrorMessageAndVisibility(false, "");
      return (isViewValid = true);
    }

    debugger;
  };

  const setEmailErrorMessageAndVisibility = (visibility, text) => {
    setEmailErrorMessageVisibility(visibility);
    setEmailErrorMessage(text);
    debugger;
  };

  //#endregion

  //#region Password Validation
  const onPasswordTextChangeListener = (event) => {
    setPassword((password = event.currentTarget.value));
    setPasswordErrorMessageAndVisibilityAndIsValid(password);
  };
  const setPasswordErrorMessageAndVisibilityAndIsValid = (enteredText) => {
    // debugger
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      // debugger
      setPasswordErrorMessageAndVisibility(true, "Password must not be empty");
    } else if (enteredText.length !== 9) {
      // debugger
      setPasswordErrorMessageAndVisibility(
        true,
        "Password must contain 9 characters"
      );
    } else {
      setPasswordErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };

  const setPasswordErrorMessageAndVisibility = (visibility, text) => {
    setPasswordErrorMessageVisibility(visibility);
    setPasswordErrorMessage(text);
  };

  //#endregion

  //#region Remember me Validation
  const rememberMeCheckChangeListener = (event) => {
    setIsRememberMeChecked(!isRememberMeChecked);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid = setEmailErrorMessageAndVisibilityAndIsValid(email);
    if (viewValid)
      viewValid = setPasswordErrorMessageAndVisibilityAndIsValid(password);
    return viewValid;
  };
  //#endregion

  //#region Api Call
  const onLoginUserClickListenerApiCall = async (e) => {
   
    let data = {
      email: email,
      password: password,
    };
    debugger;
    if (isViewValid()) {
      // alert("view is valid")
      debugger;
      RegisterAdmin(data)
        .then((res) => {
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            localStorage.setItem("Admin", JSON.stringify(res.data.result.Id));
            if(isRememberMeChecked===true){
              localStorage.setItem("IsRememberMeChecked", true);
            }else{
              localStorage.setItem("IsRememberMeChecked", false);
            }
            navigate("/dashboard");
          } else {
            alert("Invalid Email or Password");
          }
        })
        .catch((error) => {
          alert("Exception", error);
        });
    }
  };
  //#endregion

  useEffect(()=>{
    const userId = localStorage.getItem("Admin");
    const rememberMe = localStorage.getItem("IsRememberMeChecked");
    debugger;
    if(!userId){
      navigate("/");
    }
    if (rememberMe===true){
      navigate("/dashboard");
    }
    console.log("userId", userId);
    debugger;
    if (userId !== undefined && userId !== null)
      navigate("/dashboard");
  },[])

  return (
    <div className="login__container">
      <div className="login__container__content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="202.265"
          height="53.088"
          viewBox="0 0 202.265 53.088"
        >
          <g
            id="Group_489"
            data-name="Group 489"
            transform="translate(1422.524 -1010.94)"
          >
            <text
              id="PEERVEST"
              transform="translate(-1352.259 1037.94)"
              fill="#0a264e"
              font-size="28"
              font-family="CenturyGothic, Century Gothic"
            >
              <tspan x="0" y="0">
                PEERVEST
              </tspan>
            </text>
            <text
              id="GLOBAL"
              transform="translate(-1352.726 1056.94)"
              fill="#0a264e"
              font-size="16"
              font-family="CenturyGothic, Century Gothic"
            >
              <tspan x="0" y="0">
                GLOBAL
              </tspan>
            </text>
            <g
              id="Group_3"
              data-name="Group 3"
              transform="translate(-1422.524 1012.081)"
            >
              <g id="Group_2" data-name="Group 2">
                <g
                  id="Group_1"
                  data-name="Group 1"
                  transform="translate(0 0.014)"
                >
                  <path
                    id="Path_1"
                    data-name="Path 1"
                    d="M-1393.558,1025.96a1.2,1.2,0,0,0,1.035-1.8c-1.4-2.437-2.68-4.667-4.013-6.864a1.817,1.817,0,0,0-1.333-.707c-7.946-.038-15.891-.021-23.837-.009a4.424,4.424,0,0,0-.818.23l29.485,51.091a1.2,1.2,0,0,0,2.073,0c1.423-2.449,2.783-4.775,4.044-7.153.212-.4-.228-1.261-.542-1.809q-8.959-15.6-17.963-31.184l-.009-.016a1.2,1.2,0,0,1,1.04-1.786Z"
                    transform="translate(1422.524 -1016.57)"
                    fill="#d39844"
                  />
                </g>
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M-1351.485,1018.248a1.128,1.128,0,0,0-.972-1.69c-2.939-.01-5.647-.029-8.35.068-.451.016-.97.835-1.289,1.382-3.558,6.108-7.081,12.236-10.618,18.355l-.008.014a1.124,1.124,0,0,1-1.936,0c-.988-1.662-1.944-3.252-2.79-4.9a3.036,3.036,0,0,0-3.276-1.977c-2.16.136-4.332.087-6.681.06a1.128,1.128,0,0,0-.986,1.691l13.728,23.778a1.127,1.127,0,0,0,1.951,0Z"
                  transform="translate(1411.714 -1016.55)"
                  fill="#0a264e"
                />
              </g>
            </g>
          </g>
        </svg>
        <form
          onSubmit={(e)=>{
            e.preventDefault();
            onLoginUserClickListenerApiCall();
          }
          }          
          className="login__container__content__form"
        >
          <InputBox
            type="email"
            placeholder="Email Address"
            value={email}
            required
            autoFocus
            error={emailErrorMessageVisibility}
            errorMessage={emailErrorMessage}
            onChange={(event) => onEmailTextChangeListener(event)}
          // onChange={(e) => setEmail(e.currentTarget.value)}
          />

          <InputBox
            type="password"
            placeholder="Password"
            required
            value={password}
            error={passwordErrorMessageVisibility}
            errorMessage={passwordErrorMessage}
            // onChange={(e) => setPassword(e.currentTarget.value)}
            onChange={(event) => onPasswordTextChangeListener(event)}
          // style={{ marginTop: "10px", marginDown: "2px" }}
          />

          <div className="login__container__content__form__checkbox">
            <input
              className="styled-checkbox"
              id="styled-checkbox"
              type="checkbox"
              name="Remember"
              required
              value={isRememberMeChecked}
              // onChange={(e) => setRemember(e.currentTarget.value)}
              onChange={(event) => rememberMeCheckChangeListener(event)}
            />

            <label
              style={{ color: "#1c0a15", fontSize: 13 }}
              htmlFor="styled-checkbox"
            >
              Remember Me
            </label>
          </div>
          <Button
            type="submit"
            onClick={() => onLoginUserClickListenerApiCall()}
            placeholder="Login"
            className="primary__button"
          />

        </form>
      </div>
    </div>
  );
}
