import React, { useState } from "react";
import InputBox from "../Components/InputBox";
import { addMilestone } from "../Apis/Repos";
import { ERROR_CODES } from "../Constants";
import { isNullOrEmpty } from "../Utils/TextUtils";
import Loader from "./Loader";

export default function AddMilestone(props) {
  //#region other functions
  const isEdit = () => {
    return props.milestoneSlectedToEdit !== null;
  };

  const onMilestoneAddOrSaveClickListener = () => {
    if (isEdit()) editMilestoneApiCall();
    else addMilestoneApiCall();
  };
  //#endregion

  //#region Variables

  const [milestoneId, setMilestoneId] = useState(
    props.milestoneSlectedToEdit ? props.milestoneSlectedToEdit.Id : null
  );

  var [milestoneTitle, setMilestoneTitle] = useState(
    props.milestoneSlectedToEdit ? props.milestoneSlectedToEdit.Title : ""
  );
  const [milestoneTitleErrorMessage, setMilestoneTitleErrorMessage] =
    useState("");
  const [
    milestoneTitleErrorMessageVisibility,
    setMilestoneTitleErrorMessageVisibility,
  ] = useState(false);

  var [milestoneDescription, setMilestoneDescription] = useState(
    props.milestoneSlectedToEdit ? props.milestoneSlectedToEdit.Description : ""
  );
  const [
    milestoneDescriptionErrorMessage,
    setMilestoneDescriptionErrorMessage,
  ] = useState("");
  const [
    milestoneDescriptionErrorMessageVisibility,
    setMilestoneDescriptionErrorMessageVisibility,
  ] = useState(false);

  var [milestoneValue, setMilestoneValue] = useState(
    props.milestoneSlectedToEdit ? props.milestoneSlectedToEdit.Value : null
  );
  var [milestoneValueErrorMessage, setMilestoneValueErrorMessage] =
    useState("");
  var [
    milestoneValueErrorMessageVisibility,
    setMilestoneValueErrorMessageVisibility,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  //#endregion

  //#region Api Calls

  const addMilestoneApiCall = () => {
    let milestoneInfo = {
      Title: milestoneTitle,
      Description: milestoneDescription,
      Value: milestoneValue,
      IsActive: true,
    };
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addMilestone(milestoneInfo)
        .then((res) => {
          setIsLoading(false);
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            props.onMilestoneAddedSuccessfully(res.data.result);
            debugger;
          } else {
            alert("Can't add data, please try again");
          }
          console.log();
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception"+ error);
        });
    }
  };

  const editMilestoneApiCall = () => {
    let milestoneInfo = {
      Id: milestoneId,
      Title: milestoneTitle,
      Description: milestoneDescription,
      Value: milestoneValue,
      IsActive: true,
    };
    debugger;
    if (isViewValid()) {
      setIsLoading(true);
      addMilestone(milestoneInfo)
        .then((res) => {
          setIsLoading(false);
          debugger;
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            props.onMilestoneEditedSuccessfully(
              props.milestoneSlectedToEdit,
              res.data.result
            );
            debugger;
          } else {
            alert("Can't Edit Data, please try later");
          }
          console.log();
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception"+ error);
        });
    }
  };

  //#endregion

  //#region Milestone Title Validation
  const onMilestoneTitleTextChangeListener = (event) => {
    setMilestoneTitle((milestoneTitle = event.currentTarget.value));
    setMilestoneTitleErrorMessageAndVisibilityAndIsValid(milestoneTitle);
  };
  const setMilestoneTitleErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setMilestoneTitleErrorMessageAndVisibility(
        true,
        "Title must not be empty"
      );
    } else {
      setMilestoneTitleErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setMilestoneTitleErrorMessageAndVisibility = (visibility, text) => {
    setMilestoneTitleErrorMessageVisibility(visibility);
    setMilestoneTitleErrorMessage(text);
  };
  //#endregion

  //#region Milestone Value Validation
  const onMilestoneValueTextChangeListener = (event) => {
    setMilestoneValue((milestoneValue = event.currentTarget.value));
    debugger;
    setMilestoneValueErrorMessageAndVisibilityAndIsValid(milestoneValue);
  };
  const setMilestoneValueErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setMilestoneValueErrorMessageAndVisibility(
        true,
        "Value must not be empty"
      );
    } else if (isNaN(enteredText)) {
      setMilestoneValueErrorMessageAndVisibility(true, "Digits only");
    } else {
      setMilestoneValueErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setMilestoneValueErrorMessageAndVisibility = (visibility, text) => {
    setMilestoneValueErrorMessageVisibility(visibility);
    setMilestoneValueErrorMessage(text);
  };
  //#endregion

  //#region Milestone Description Validation
  const onMilestoneDescriptionTextChangeListener = (event) => {
    setMilestoneDescription((milestoneDescription = event.currentTarget.value));
    // setMilestoneDescriptionErrorMessageAndVisibilityAndIsValid(
    //   milestoneDescription
    // );
  };
  const setMilestoneDescriptionErrorMessageAndVisibilityAndIsValid = (
    enteredText
  ) => {
    var isViewValid = false;
    if (isNullOrEmpty(enteredText)) {
      setMilestoneDescriptionErrorMessageAndVisibility(
        true,
        "Description must not be empty"
      );
    } else {
      setMilestoneDescriptionErrorMessageAndVisibility(false, "");
      isViewValid = true;
    }
    return isViewValid;
  };
  const setMilestoneDescriptionErrorMessageAndVisibility = (
    visibility,
    text
  ) => {
    setMilestoneDescriptionErrorMessageVisibility(visibility);
    setMilestoneDescriptionErrorMessage(text);
  };
  //#endregion

  //#region View Valid
  const isViewValid = () => {
    var viewValid =
      setMilestoneTitleErrorMessageAndVisibilityAndIsValid(milestoneTitle);
    if (viewValid)
      viewValid =
        setMilestoneValueErrorMessageAndVisibilityAndIsValid(milestoneValue);
    // if (viewValid)
    //   viewValid =
    //     setMilestoneDescriptionErrorMessageAndVisibilityAndIsValid(
    //       milestoneDescription
    //     );

    return viewValid;
  };
  //#endregion

  return (
    <div className="popup__container">
      {
        isLoading ? (
          <Loader/>
        ) : (

      <div action="" className="popup__container__form">
        <div className="popup__container__form__header">
          <div>{isEdit() ? "Edit Milestone" : "Add Milestone"}</div>
          <button
            onClick={() => {
              props.onDialogCrossClicked(false);
            }}
            className="popup__container__form__close__btn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.829"
              height="14.829"
              viewBox="0 0 14.829 14.829"
            >
              <g id="x" transform="translate(-4.586 -4.586)">
                <line
                  id="Line_20"
                  data-name="Line 20"
                  x1="12"
                  y2="12"
                  transform="translate(6 6)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
                <line
                  id="Line_21"
                  data-name="Line 21"
                  x2="12"
                  y2="12"
                  transform="translate(6 6)"
                  fill="none"
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                />
              </g>
            </svg>
          </button>
        </div>
        <div className="popup__container__form__heading">Title</div>
        <InputBox
          placeholder="Title"
          type="text"
          value={milestoneTitle}
          error={milestoneTitleErrorMessageVisibility}
          errorMessage={milestoneTitleErrorMessage}
          onChange={(event) => onMilestoneTitleTextChangeListener(event)}
        />
        <div className="popup__container__form__heading">Value</div>
        <InputBox
          placeholder="Value"
          type="text"
          value={milestoneValue}
          error={milestoneValueErrorMessageVisibility}
          errorMessage={milestoneValueErrorMessage}
          onChange={(event) => onMilestoneValueTextChangeListener(event)}
        />
        <div className="popup__container__form__heading">Descrition (optional)</div>
        <InputBox
          placeholder="Description"
          type="text"
          variant="textbox"
          value={milestoneDescription}
          error={milestoneDescriptionErrorMessageVisibility}
          errorMessage={milestoneDescriptionErrorMessage}
          onChange={(event) => onMilestoneDescriptionTextChangeListener(event)}
        />
        <p className="homepage_container_jumbotron_signup_wrapper_input_form_error_message">
          {milestoneDescriptionErrorMessage}
        </p>
        <button
          onClick={() => {
            onMilestoneAddOrSaveClickListener();
          }}
          style={{ marginTop: "1.5em", marginBottom: "1em" }}
          className="primary__button"
        >
          {isEdit() ? "Edit" : "Add"}
        </button>
      </div>
        )
      }
    </div>
  );
}
