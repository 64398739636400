import React from "react";
import Select from "react-select";

export default function MultiSelectInput({
  placeholder,
  options,
  isMulti,
  onChange,
  value,
  error,
  errorMessage,
}) {
  return (
    <div className="multi__select__input__box">
      <Select
        options={options}
        placeholder={placeholder}
        isMulti={isMulti}
        onChange={onChange}
        value={value}
      />
      {error ? (
        <div className="login__container__content__form__input__error">
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
}
