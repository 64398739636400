import React, { useState, useEffect } from "react";
import UserStatisticsChart from "../Components/UserStatisticsChart";
import chart from "../Assets/Chart.png";
import userPic from "../Assets/userPic.png";
import { useNavigate } from "react-router-dom";
import {
  getInvestorsArray,
  getStartupArray,
  setInvestorsArray,
  setStartupArray,
} from "../GlobalVariables";
import Loader from "./Loader";
import { GetAllInvestor, GetAllStartup } from "../Apis/Repos";
import { ERROR_CODES } from "../Constants";

function UserFrom({ stroke, label }) {
  return (
    <div className="user__statistics__card__content__right__from">
      <div
        className="user__statistics__card__content__right__from__dot"
        style={{ backgroundColor: stroke }}
      ></div>
      {label}
    </div>
  );
}

function UserStatisticsCard() {
  return (
    <div className="user__statistics__card">
      <div className="user__statistics__card__header">
        <div className="user__statistics__card__header__heading">
          User From sites
        </div>
        <div className="user__statistics__card__header__date">
          15 April - 15 May
        </div>
      </div>
      <div className="user__statistics__card__content">
        <div className="user__statistics__card__content__left">
          <UserStatisticsChart />
        </div>
        <div className="user__statistics__card__content__right">
          <UserFrom stroke="#D39844" label="Facebook" />
          <UserFrom stroke="#0A2751" label="Instagram" />
          <UserFrom stroke="#FFD497" label="Youtube" />
          <UserFrom stroke="#0B5FD8" label="Google" />
        </div>
      </div>
    </div>
  );
}

function StatsCard({ stroke }) {
  return (
    <div className="stats__card" style={{ backgroundColor: stroke }}>
      <img src={chart} alt="chart" className="stats__card__img" />
      <div className="stats__card__content">
        <div className="stats__card__content__heading">Total Expense</div>
        <div className="stats__card__content__value">$12,900</div>
      </div>
    </div>
  );
}
function MonthlyStatsCard({ stroke, labal, valueInPercentage }) {
  return (
    <div className="monthly__stats__card">
      <div className="monthly__stats__card__header">
        <div className="monthly__stats__card__heading">{labal}</div>
        <div className="monthly__stats__card__sub__heading">This Month</div>
      </div>
      <div className="monthly__stats__card__content">
        <div className="monthly__stats__card__bar">
          <div
            className="monthly__stats__card__bar__filled"
            style={{ minWidth: valueInPercentage, backgroundColor: stroke }}
          ></div>
        </div>
        <div className="monthly__stats__card__content__value">56</div>
      </div>
    </div>
  );
}

function InvestorCard({ investorDataToPass }) {
  debugger;
  return (
    <div className="recent__panel__content__card">
      <div className="recent__panel__content__card__content">
        <div className="recent__panel__content__card__content__heading">
          Fundraise name{" "}
          <span>
            {investorDataToPass.usermeta[0]
              ? investorDataToPass.usermeta[0].UserValue
              : ""}
          </span>
        </div>
        <div className="recent__panel__content__card__content__heading">
          Investor Type{" "}
          <span>
            {investorDataToPass.usermeta[1]
              ? investorDataToPass.usermeta[1].UserValue
              : ""}
          </span>
        </div>
        <div className="recent__panel__content__card__content__heading">
          Ticket Size{" "}
          <span>
            {investorDataToPass.usermeta[3]
              ? investorDataToPass.usermeta[3].UserValue
              : ""}
          </span>
        </div>
        <div className="recent__panel__content__card__content__heading">
          Business Model{" "}
          <span>
            {investorDataToPass.usermeta[5]
              ? investorDataToPass.usermeta[5].UserValue
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}

function StartupCard({ startupDataToPass }) {
  debugger;
  return (
    <div className="recent__panel__content__card">
      <img
        src={process.env.REACT_APP_API_URL + startupDataToPass.attachment_path}
        alt="userPic"
        className="recent__panel__content__card__img"
      />
      <div className="recent__panel__content__card__content">
        <div className="recent__panel__content__card__content__heading">
          Fundraise name{" "}
          <span>
            {startupDataToPass.usermeta[0]
              ? startupDataToPass.usermeta[0].UserValue
              : ""}
          </span>
        </div>
        <div className="recent__panel__content__card__content__heading">
          Industry{" "}
          <span>
            {startupDataToPass.usermeta[2]
              ? startupDataToPass.usermeta[2].UserValue
              : ""}
          </span>
        </div>
        <div className="recent__panel__content__card__content__heading">
          Stage{" "}
          <span>
            {startupDataToPass.usermeta[3]
              ? startupDataToPass.usermeta[3].UserValue
              : ""}
          </span>
        </div>
        <div className="recent__panel__content__card__content__heading">
          Business Model{" "}
          <span>
            {startupDataToPass.usermeta[4]
              ? startupDataToPass.usermeta[4].UserValue
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}

function RecentPanel({ getInvestorsArray, startupData, investorData, isLoading }) {
  console.log("getInvestorsArray in recent panel", getInvestorsArray());
  const [selected, setSelected] = useState("startup");
  return (
    <div className="recent__panel">
      <div className="recent__panel__header">
        <div className="recent__panel__header__button">
          <input
            type="radio"
            defaultChecked
            onClick={() => {
              setSelected("startup");
            }}
            name="recent__panel__header__button__input"
            className="recent__panel__header__button__input"
          />
          <div className="recent__panel__header__button__content">Startup</div>
        </div>
        <div className="recent__panel__header__button">
          <input
            type="radio"
            onClick={() => {
              setSelected("investor");
            }}
            name="recent__panel__header__button__input"
            className="recent__panel__header__button__input"
          />
          <div className="recent__panel__header__button__content">Investor</div>
        </div>
      </div>
      <div className="recent__panel__content">
        {selected === "startup" ? (
          <>
            {
              isLoading ? (
                <Loader/>
              ) : (
                startupData.length > 0 && startupData !== null ? (
                  startupData.map((startup) => (
                    <StartupCard startupDataToPass={startup} />

              )
              )):<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>
            ) }
          </>
        ) : selected === "investor" ? (
          <>
            {
              isLoading ? (
                <Loader/>
              ) : (
                getInvestorsArray().length > 0 && getInvestorsArray() !== null ? (
                  getInvestorsArray().map((investor) => (
                    <InvestorCard investorDataToPass={investor} />
                  )
                  )):<div style={{fontSize:"30px", textAlign: "center", marginTop: "50px", color: "black"}}>Data Not Found</div>

              )
            }
          </>
        ) : null}
      </div>
    </div>
  );
}

export default function Dashboard() {
  const navigate = useNavigate();
  const [startupData, setStartupData] = useState(getStartupArray());
  const [investorData, setInvestorData] = useState(getInvestorsArray());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (getStartupArray().length > 0 && getStartupArray() !== null) {
      debugger;
    } else {
      setIsLoading(true);
      GetAllStartup()
        .then((res) => {
          setIsLoading(false);
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            setStartupArray(res.data.result);
            setStartupData(getStartupArray());
            debugger;
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception", error);
        });
    }

    if (getInvestorsArray().length > 0 && getInvestorsArray() !== null) {
      debugger;
    } else {
      setIsLoading(true);
      GetAllInvestor()
        .then((res) => {
          setIsLoading(false);
          if (res.data.success === ERROR_CODES.SUCCESS) {
            debugger;
            setInvestorsArray(res.data.result);
            setInvestorData(getStartupArray());
            debugger;
          }
        })
        .catch((error) => {
          setIsLoading(false);
          alert("Exception", error);
        });
    }
    const userId = localStorage.getItem("Admin");
    if(!userId){
      navigate("/");
    }
  }, []);

  return (
    <div className="dashboard__container">
      <div className="dashboard__container__col">
        <UserStatisticsCard />
        <StatsCard stroke="#0A2751" />
      </div>
      <div className="dashboard__container__col">
        <MonthlyStatsCard
          valueInPercentage="56%"
          stroke="#0A2751"
          labal="Investor"
        />
        <MonthlyStatsCard
          valueInPercentage="40%"
          stroke="#DFB57A"
          labal="Startup"
        />
        <StatsCard stroke="#D39844" />
      </div>
      <div className="dashboard__container__col">
        <RecentPanel
          getInvestorsArray={getInvestorsArray}
          startupData={startupData}
          investorData={investorData}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
}
