import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import Checkout from "../Components/Checkout";
import EntryLine from "../Components/EntryLine";
import Summary from "../Components/Summary";
import profile_image from "../Assets/profile_image.jpg";

const StartupFormTemplate = ({ startupData }) => (
  <PDFViewer
    style={{
      width: "95%",
      height: "95%",
      border: "none",
      borderRadius: 10,
      marginTop: 35,
      marginLeft: 20,
    }}
  >
    <Document>
      <Page size="A4" style={{ backgroundColor: "#F8F8F8" }}>
        <View style={{ paddingHorizontal: 20 }}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: 30,
                marginTop: 30,
              }}
            >
              <Image
                source={
                  startupData && startupData.attachment_path !== null
                    ? process.env.REACT_APP_API_URL +
                      startupData.attachment_path
                    : profile_image
                }
                style={{
                  borderRadius: "50px",
                  width: "80px",
                  height: "80px",
                }}
              />
            </View>
          </View>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 70,
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <EntryLine
                Heading="Name"
                subHeading={
                  startupData && startupData.usermeta[0]
                    ? startupData.usermeta[0].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Email"
                subHeading={startupData ? startupData.Email : ""}
              />
              <EntryLine
                Heading="Website"
                subHeading={
                  startupData && startupData.usermeta[1]
                    ? startupData.usermeta[1].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up Industry"
                subHeading={
                  startupData.usermeta[2]
                    ? startupData.usermeta[2].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up Stage"
                subHeading={
                  startupData && startupData.usermeta[3]
                    ? startupData.usermeta[3].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up Model"
                subHeading={
                  startupData && startupData.usermeta[4]
                    ? startupData.usermeta[4].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Problem"
                subHeading={
                  startupData && startupData.usermeta[5]
                    ? startupData.usermeta[5].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up incorporating technology"
                subHeading={
                  startupData && startupData.usermeta[7]
                    ? startupData.usermeta[7].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Unique Value Proposition"
                subHeading={
                  startupData && startupData.usermeta[10]
                    ? startupData.usermeta[10].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up description"
                subHeading={
                  startupData && startupData.usermeta[6]
                    ? startupData.usermeta[6].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Local Competitors"
                subHeading={
                  startupData && startupData.usermeta[8]
                    ? startupData.usermeta[8].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Global Competitors"
                subHeading={
                  startupData && startupData.usermeta[9]
                    ? startupData.usermeta[9].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up traction"
                subHeading={
                  startupData && startupData.usermeta[11]
                    ? startupData.usermeta[11].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Total members of funding team"
                subHeading={
                  startupData && startupData.usermeta[12]
                    ? startupData.usermeta[12].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Contact Details"
                subHeading={
                  startupData && startupData.usermeta[13]
                    ? startupData.usermeta[13].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Details of founding team"
                subHeading={
                  startupData && startupData.usermeta[14]
                    ? startupData.usermeta[14].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up plan on Raising fund"
                subHeading={
                  startupData && startupData.usermeta[15]
                    ? startupData.usermeta[15].UserValue
                    : ""
                }
              />
              <EntryLine
                Heading="Start-up Earlier funding"
                subHeading={
                  startupData && startupData.usermeta[16]
                    ? startupData.usermeta[16].UserValue
                    : ""
                }
              />
              {/* <Text style={{ fontSize: 14, marginTop: 20 }}>Details</Text>
              <EntryLine
                Heading="Invoice number"
                subHeading={"invoiceNumber"}
              />
              <EntryLine Heading="Invoice date" subHeading={"DD MMM, YYYY"} /> */}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  </PDFViewer>
);

export default StartupFormTemplate;
