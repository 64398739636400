var INVESTORS_ARRAY = []
var STARTUP_ARRAY = []

export const setInvestorsArray = (value) => {
  INVESTORS_ARRAY = value;
};

export const getInvestorsArray = () => {
  return INVESTORS_ARRAY
}

export const setStartupArray = (value) => {
  STARTUP_ARRAY = value
}

export const getStartupArray = () => {
  return STARTUP_ARRAY
}
