import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StartupFormTemplate from "./StartupFormTemplate";

export default function StartUpForm() {
  const location = useLocation();
  const navigate = useNavigate();

  const [startupData, setStartupData] = useState(
    location.state ? location.state.startUpDataToPass : null
  );

  return (
    <div
      style={{
        width: "74%",
        display: "flex",
        flexDirection: "column",
        background: "white",
        borderRadius: "20px",
      }}
    >
      <div
        className="main__container__sub__heading"
        style={{ marginLeft: "2em", marginTop: "2em" }}
      >
        <button
          onClick={() => {
            navigate("/Dashboard/startup");
          }}
          className="main__container__sub__heading__btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20.556"
            height="17.629"
            viewBox="0 0 20.556 17.629"
          >
            <g id="_507257" data-name="507257" transform="translate(0 -31.842)">
              <g
                id="Group_506"
                data-name="Group 506"
                transform="translate(0 31.842)"
              >
                <path
                  id="Path_753"
                  data-name="Path 753"
                  d="M19.32,39.213a1.469,1.469,0,0,0-.254-.019H4.564l.316-.147a2.941,2.941,0,0,0,.831-.588l4.067-4.067a1.522,1.522,0,0,0,.213-1.949,1.471,1.471,0,0,0-2.206-.191L.431,39.606a1.471,1.471,0,0,0,0,2.08h0l7.354,7.354a1.471,1.471,0,0,0,2.206-.147,1.522,1.522,0,0,0-.213-1.949L5.718,42.871a2.942,2.942,0,0,0-.735-.537l-.441-.2H18.984A1.522,1.522,0,0,0,20.536,40.9,1.471,1.471,0,0,0,19.32,39.213Z"
                  transform="translate(0 -31.842)"
                  fill="#d39844"
                />
              </g>
            </g>
          </svg>
        </button>
        Startup Details
      </div>
      <StartupFormTemplate startupData={startupData} />
    </div>
  );
}
