import { Text, View } from "@react-pdf/renderer";

function Summary({ description }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        marginTop: 6,
      }}
    >
      <Text
        style={{
          fontSize: 10,
          color: "black",
          fontWeight: "bold",
          width: "55%",
        }}
      >
        {description}
      </Text>
    </View>
  );
}

export default Summary;
