import { Text, View } from "@react-pdf/renderer";

function EntryLine({ Heading, subHeading }) {
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5,
        marginLeft: 10,
      }}
    >
      <Text
        style={{
          marginRight: 20,
          width: "40%",
          fontSize: 10,
          color: "black",
          fontWeight: "bold",
        }}
      >
        {Heading}
      </Text>
      <Text
        style={{
          //   marginRight: 10,
          fontSize: 10,
          width: "55%",
          color: "#2C4C4C",
          fontWeight: "bold",
          textAlign: "justify",
        }}
      >
        {subHeading}
      </Text>
    </View>
  );
}

export default EntryLine;
