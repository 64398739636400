import React from "react";
import { useNavigate } from "react-router-dom";

export default function Button({
  placeholder,
  style,
  className,
  path,
  onClick,
  variant,
  svg,
  type,
}) {
  const navigate = useNavigate();
  if (variant === "rounded") {
    return (
      <>
        <button
          type={type ? type : "button"}
          onClick={
            onClick
              ? onClick
              : () => {
                  navigate(path);
                }
          }
          className={className}
          style={style}
        >
          {svg}
        </button>
      </>
    );
  } else {
    return (
      <>
        <button
          type={type ? type : "button"}
          onClick={
            onClick
              ? onClick
              : () => {
                  navigate(path);
                }
          }
          className={className}
          style={style}
        >
          {placeholder}
        </button>
      </>
    );
  }
}
