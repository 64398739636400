module.exports = {
  ERROR_CODES: {
    SUCCESS: true,
    ERROR_OCCURRED: 1, // In case of exception or any error
    // LIST_IS_EMPTY: 2,
    // CODE_EXPIRED: 3,
    // USER_IS_NOT_VERIFIED: 4,
  },
  VALIDATION_REGEX: {
    DIGITS_ONLY: /^[0-9]*$/,
    DIGITIS_WITH_DECIMAL_POINT: /^[1-9]\d*(\.\d+)?$/,
    ALPHABETS_ONLY: /^[a-zA-Z ]+$/,
    EMAIL_REGEX: /\S+@\S+\.\S+/,
    PHONE_NUMBER: /^[0-9*#+]+$/,
    WEBSITE_URL: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
  },
  USER_KEYS: {
    INVESTOR_USER_TYPE: 1,
    INVESTOR_NAME: "Name",
    INVESTOR_TYPE: "Investor Type",
    INVESTOR_STARTUP_STAGE: "Startup Stage interested in to invest",
    INVESTOR_TICKET_SIZE: "Ticket Size to invest",
    INVESTOR_INDUSTRY: "Industry Interested In",
    INVESTOR_BUSINESS_MODEL: "Business Model Interest In",
    INVESTOR_EXIT_PLAN: "Exit Plan",

    STARTUP_USER_TYPE: 2,
    STARTUP_NAME: "Start-Up Name",
    STARTUP_WEBSITE: "Website/Page URL",
    STARTUP_INDUSTRY: "Start-Up Industry",
    STARTUP_STAGE: "Start-Up Stage",
    STARTUP_MODEL: "Start-Up Model",
    STARTUP_PROBLEM: "Problem Your Start-Up Solving",
    STARTUP_DESCRIPTION: "Describe Your Start-Up",
    STARTUP_TECHNOLOGY: "Start-Up Incorporating Technology",
    STARTUP_LOCAL_COMPETITORS: "Local Competitors",
    STARTUP_GLOBAL_COMPETITORS: "Global Competitors",
    STARTUP_UVP: "Start-Up Unique Value Proposition (UVP)",
    STARTUP_TRACTION: "Start-Up Traction",
    STARTUP_TOTAL_MEMBERS_OF_FUNDING_TEAM: "Total Members Of Founding Team",
    STARTUP_FUNDING_TEAM_MEMBERS_DETAILS: "Details Of The Founding Team Member(S)",
    STARTUP_CONTACT_DETAILS: "Contact Details ",
    STARTUP_PLAN_ON_RAISING_FUND: "Start-Up Plan On Raising Funding",
    STARTUP_FUND_RAISED_BEFORE: "Has Your Start-Up Raised Funding Before?",


  }
};
